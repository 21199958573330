.post {
	width: 50%;
	background-color: white;
	max-width: 650px;
	border-radius: 20px;
	margin-bottom: 20px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.avatar {
	border: 2px solid #188fff9d;
}

.photo {
	border-radius: 10px;
}
