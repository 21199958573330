.header {
	height: 60px;
	background-color: white;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 14px 90px;
}

.stories {
	max-width: 650px;
	border-radius: 20px;
	margin: 30px auto;
	margin-bottom: 20px;
	width: 50%;
	background-color: white;
	height: 100px;
}

.create-post {
	border-radius: 20px !important;
	width: 50%;
	height: 50px !important;
	max-width: 650px;
	margin: 25px auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.posts {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.dialog-button {
	border-radius: 17px !important;
	margin: 5px;
	height: 35px !important;
}

